import React from 'react'
//import { Link } from 'gatsby'
import { Container as BlockContainer } from '../Blocks'
import { Container, Row, Col, Form, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Label, Input, FormGroup, Button } from 'reactstrap'

class Inquiry extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            dropdownOpenInquiryFor: false,
            dropdownOpenInquiryItem: false,
            inquireFor: null,
            inquireItem: null,
            firstName: '',
            lastName: '',
            emailAddress: '',
            phoneNumber: '',
            company: '',
            memberCode: '',
            subject: '',
            message: '',
            termsAndCondition: true, // for default
            agreeForContact: false
        }
    }

    toggleInquiryFor = () => {
        this.setState((state,props) => {
            return { dropdownOpenInquiryFor: !state.dropdownOpenInquiryFor }
        })
    }

    toggleInquiryItem = () => {
        this.setState((state,props) => {
            return { dropdownOpenInquiryItem: !state.dropdownOpenInquiryItem }
        })
    }

    send = (e) => {
        e.preventDefault()

        if (!this.state.inquireFor || !this.state.inquireItem) {
            alert('Please select first some options on the Inquiry selection')
            return;
        }

        if (!this.state.termsAndCondition || !this.state.agreeForContact) {
            alert('Please agree first on the following questions in order to proceed.')
            return;
        }
        
        let formData = new FormData()
        for (let key in this.state) {
            formData.append(key, this.state[key])
        }

        fetch('https://api.sidc.coop/website/consumer/sendInquiry.php', {
            method: 'POST', body: formData
        })
        .then(response => response)
        .then(data => {
            alert('Your Inquiry has been sent. Please wait for us to contact you. Thank you')
            return data
        })
        .catch(error => {
            console.log(error)
            alert('Failed to send your inquiry to our system. Please contact us via our email address or via phone. Thank you.')
        })

        this.reset()
    }

    reset = () => {
        this.setState({
            dropdownOpenInquiryFor: false,
            dropdownOpenInquiryItem: false,
            inquireFor: null,
            inquireItem: null,
            firstName: '',
            lastName: '',
            emailAddress: '',
            phoneNumber: '',
            company: '',
            memberCode: '',
            subject: '',
            message: '',
            termsAndCondition: false,
            agreeForContact: false
        })
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleCheckChange = (e) => {
        this.setState({ [e.target.name]:  e.target.checked })
    }

    updateInquiryFor = (value) => {
        this.setState({ inquireFor: value })
    }

    updateInquiryItem = (value) => {
        this.setState({ inquireItem: value })
    }

    render() {
        return (
            <BlockContainer padding={3} center={false}>
                <Container>
                    <Row>
                        <Col sm="6">
                            <div className="mb-3" style={{ display: 'flex', justifyContent: 'center' }}>
                                <ButtonDropdown isOpen={this.state.dropdownOpenInquiryFor} toggle={this.toggleInquiryFor}>
                                    <DropdownToggle color="primary" caret>
                                        I am inquiring for
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem onClick={() => { this.updateInquiryFor('General Information')}} active={this.state.inquireFor === 'General Information'}>General Information</DropdownItem>
                                        <DropdownItem onClick={() => { this.updateInquiryFor('Franchise')}} active={this.state.inquireFor === 'Franchise'}>Franchise</DropdownItem>
                                        <DropdownItem onClick={() => { this.updateInquiryFor('Contract Growing')}} active={this.state.inquireFor === 'Contract Growing'}>Contract Growing</DropdownItem>
                                        <DropdownItem onClick={() => { this.updateInquiryFor('Product Informations')}} active={this.state.inquireFor === 'Product Informations'}>Product Informations</DropdownItem>
                                        <DropdownItem onClick={() => { this.updateInquiryFor('Investments')}} active={this.state.inquireFor === 'Investments'}>Investments</DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                            </div>
                        </Col>
                        <Col sm="6">
                            <div className="mb-3" style={{ display: 'flex', justifyContent: 'center' }}>
                                <ButtonDropdown isOpen={this.state.dropdownOpenInquiryItem} toggle={this.toggleInquiryItem}>
                                    <DropdownToggle color="primary" caret>
                                        Contact me
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem onClick={() => { this.updateInquiryItem('Send me an Email')}} active={this.state.inquireItem === 'Send me an Email'}>Send me an Email</DropdownItem>
                                        <DropdownItem onClick={() => { this.updateInquiryItem('Please call me on phone')}} active={this.state.inquireItem === 'Please call me on phone'}>Please call me on phone</DropdownItem>
                                        <DropdownItem onClick={() => { this.updateInquiryItem('Appointment with a field agent')}} active={this.state.inquireItem === 'Appointment with a field agent'}>Appointment with a field agent</DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem onClick={() => { this.updateInquiryItem('Other')}} active={this.state.inquireItem === 'Other'}>Other</DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12" className="p-4">
                            <h3 className="mb-3">Please include contact informations below</h3>
                            <Form id="inquiry" onSubmit={this.send}>
                                <Row form>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Input value={this.state.inquireFor} type="text" name="inquireFor" id="inquireFor" placeholder="I am inquiring for" disabled required />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Input value={this.state.inquireItem} type="text" name="inquireItem" id="inquireItem" placeholder="About" disabled required />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Input value={this.state.firstName} onChange={this.handleChange} type="text" name="firstName" id="firstName" placeholder="First Name" required />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Input value={this.state.lastName} onChange={this.handleChange} type="text" name="lastName" id="lastName" placeholder="Last Name" required />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <FormGroup>
                                    <Input value={this.state.company} onChange={this.handleChange} type="text" name="company" id="company" placeholder="Company" required />
                                </FormGroup>
                                <FormGroup>
                                    <Input value={this.state.emailAddress} onChange={this.handleChange} type="email" name="emailAddress" id="emailAddress" placeholder="Email" required />
                                </FormGroup>
                                <Row form>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Input value={this.state.phoneNumber} onChange={this.handleChange} type="text" name="phoneNumber" id="phoneNumber" placeholder="Phone (optional)" />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Input value={this.state.memberCode} onChange={this.handleChange} type="text" name="memberCode" id="memberCode" placeholder="Member Code" required />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <FormGroup>
                                    <Input value={this.state.subject} onChange={this.handleChange} type="text" name="subject" id="subject" placeholder="Subject" required />
                                </FormGroup>
                                <FormGroup>
                                    <Input value={this.state.message} onChange={this.handleChange} type="textarea" name="message" id="message" placeholder="Message" required row="5" />
                                </FormGroup>
                                {/* <FormGroup>
                                    <Label check style={{ marginLeft: "15px"}}>
                                        <Input checked={this.state.termsAndCondition} value={this.state.termsAndCondition} onChange={this.handleCheckChange} type="checkbox" name="termsAndCondition" id="termsAndCondition" />I Agree with <Link to="/terms-and-condition">Term and Conditions</Link> of the service
                                    </Label>
                                </FormGroup> */}
                                <FormGroup>
                                    <Label check style={{ marginLeft: "15px"}}>
                                        <Input checked={this.state.agreeForContact} value={this.state.agreeForContact} onChange={this.handleCheckChange} type="checkbox" name="agreeForContact" id="agreeForContact" />I Agree Consumer Retail and SIDC will contact me via Phone or email
                                    </Label>
                                </FormGroup>
                                <FormGroup>
                                    <Button color="primary">Submit</Button>
                                </FormGroup>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </BlockContainer>
        )
    }

}

export default Inquiry